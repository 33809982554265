<!-- 解决方案 -->
<template>
  <div class="wrapper pageBox">
    <!-- banner -->
    <solutionBanner id="solutionBanner" ref="solutionBanner" :from="'zzgl'" />
    <!-- 二级导航 -->
    <navBar ref="navBar" :from="'Scheme'" />
    <!-- 行业分析 -->
    <div id="hyfx_div" class="fenxi-box">
      <h1 class="h1-title">行业分析</h1>
      <div class="fenxi conBox flex-between flex-align">
        <div class="imgs">
          <img class="img" src="@/assets/solution/cyyq/fenxi-zz.png">
        </div>
        <div>
          <div class="text" style="margin-top:15px">
            当前各行政部门多存在如下痛点：制证权限过度集中于科室，出件不可控；电子证照签发不及时、签发率低；各行政单位在受理、审批相应的事项时，证照信息复用率低， 资源利用率不高。
          </div>
          <div class="text" style="margin-top:15px">
            因此我们着眼于政务信息化建设全局，以政务信息资源共享基础设施为基础，构建证照管理系统，致力于实现如下特色功能。
          </div>
          <div class="text" style="margin-top:15px">
            <span class="text-title">1. 全量证照数据汇集：</span>对接各种业务系统，汇集各种证照照面数据信息，包括新增证照及存量证照信息。<br>
            <span class="text-title">2. 实体电子证照同步签发：</span>实现各个科室证照统一签发，即证照统一打印、统一盖章、统一归档、统一发证，并支持柜台取证、自助机取证、邮寄取证等多种取证方式进行取证。在生成实体证照的同时，100%自动签发电子证照。<br>
            <span class="text-title">3. 证照数据实时共享：</span>基于数据共享体系，可快速支撑系统与多种业务系统及部门间的数据互联互通，实现电子证照线上政务办事关联应用，线下政务、社会场景证照调用应用。
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案能力全景 -->
    <div id="faqj_div" class="conBox nlfa">
      <h1 class="h1-title">解决方案能力全景</h1>
      <img class="img" src="@/assets/solution/cyyq/nlfa-zz.png">
      <img class="img1" src="@/assets/solution/cyyq/jjfabg-zw.png">
    </div>
    <!-- 平台典型功能介绍 -->
    <carouselBanner id="ptgn_div" ref="carouselBanner" :from="'zzgl'" />
    <!-- 适用场景 -->
    <div id="sycj_div" class="sycj-div">
      <h1 class="h1-title">适用场景</h1>
      <sycj ref="sycj" class="conBox" :from="'zzgl'" />
    </div>
    <!-- 数字嘉园 美好生活 -->
    <footers ref="footers" />
  </div>
</template>

<script>
import solutionBanner from './components/solutionBanner.vue';
import sycj from './components/sycj.vue';
import footers from './components/footer.vue';
import carouselBanner from './components/carouselBanner.vue';
import navBar from './components/navBar.vue';
export default {
  name: 'Solution',
  components: { solutionBanner, sycj, footers, carouselBanner, navBar },
  data() {
    return {
    };
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>

.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.conBox {
  width: 1200px;
  margin: 0 auto;
}
.fenxi-box {
  padding-bottom: 80px;
  background: #F9FBFF;
  .fenxi {
    .imgs,.img{
      position: relative;
      width: 594px;
      height: 380px;
      .color-block {
        position: absolute;
        bottom: -24px;
        left: -32px;
        width: 73px;
        height: 73px;
        background: #30a5ff;
      }
    }
    .text {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 30px;
      padding-left: 50px;
      text-align: justify;
      .text-title {
        // color: #30a5ff;
        font-weight: bold;
      }
    }
  }
}
.nlfa {
  position: relative;
  text-align: center;
  margin-bottom: 80px;
  .img {
    width: 1229px;
    height: 660px;
    margin: auto;
  }
  .img1 {
    position: absolute;
    width: 603px;
    height: 506px;
    top: 250px;
    right: -181px;
  }
}
</style>

